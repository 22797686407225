@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap";
.MZwSAW_avatar {
  letter-spacing: -.01rem;
  color: var(--alku-black);
  border: .2rem solid #333;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
}

.MZwSAW_avatar.MZwSAW_sm {
  width: 4rem;
  height: 4rem;
  margin-right: .6rem;
}

.MZwSAW_avatar.MZwSAW_md {
  width: 4.4rem;
  height: 4.4rem;
}

._0RedaG_primary-impact, ._0RedaG_primary-default {
  color: var(--alku-white);
  background-color: var(--alku-brand-primary);
  min-width: 14.5rem;
  font-size: 1.6rem;
}

._0RedaG_primary-impact {
  color: var(--alku-white);
  background-color: var(--alku-brand-primary);
  border: none;
  padding: 1.5rem 3.6rem;
}

._0RedaG_primary-impact:hover {
  background-color: #f5001e;
}

._0RedaG_primary-impact:active {
  background-color: #a50014;
}

._0RedaG_primary-default {
  color: var(--alku-black);
  background-color: var(--alku-grey-1);
  border: var(--alku-border-default);
  padding: 1.4rem 3.5rem;
}

._0RedaG_primary-default:hover {
  background-color: var(--alku-white);
}

._0RedaG_primary-default:active {
  border-color: #383a42;
}

:is(._0RedaG_primary-default, ._0RedaG_primary-impact, ._0RedaG_secondary):disabled {
  opacity: .5;
}

._0RedaG_secondary {
  min-width: 12.9rem;
  max-height: 3.8rem;
  padding: .8rem 1.2rem;
  font-size: 1.3rem;
  font-weight: 600;
}

._0RedaG_secondary:hover {
  background-color: var(--alku-white);
}

._0RedaG_secondary:active {
  border-color: var(--alku-icon-default);
}

._0RedaG_function {
  padding: 1.1rem 1.3rem;
}

._0RedaG_secondary, ._0RedaG_function {
  color: var(--alku-black);
  border: var(--alku-border-default);
  background-color: var(--alku-grey-1);
}

:is(._0RedaG_secondary, ._0RedaG_function):hover {
  background-color: var(--alku-white);
}

:is(._0RedaG_secondary, ._0RedaG_function):active {
  border-color: var(--alku-icon-default);
}

._0RedaG_primary-default, ._0RedaG_primary-impact, ._0RedaG_secondary, ._0RedaG_function {
  letter-spacing: -.01rem;
  text-align: center;
  border-radius: .1rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: all .3s;
  display: inline-flex;
}

:is(._0RedaG_primary-default, ._0RedaG_primary-impact, ._0RedaG_secondary, ._0RedaG_function):focus {
  box-shadow: 0 0 1rem #09377c80;
}

._0RedaG_icon-only {
  background-color: #0000;
  border: none;
  align-items: center;
  padding: 0;
  display: flex;
}

._0RedaG_icon-only:disabled, ._0RedaG_icon-only[aria-disabled] {
  color: var(--alku-icon-disabled);
}

:is(._0RedaG_icon-only:disabled, ._0RedaG_icon-only[aria-disabled]):hover {
  cursor: not-allowed;
}

._0RedaG_link {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

:root {
  --alku-brand-primary: #d0021b;
  --alku-brand-primary-light: #f9efef;
  --alku-icon-default: #383a42;
  --alku-icon-disabled: #888;
  --alku-grey-1: #f9f9f9;
  --alku-white: #fff;
  --alku-black: #000;
  --alku-border-default: .1rem solid #707483;
  --shadow-modal: 0 .1rem .4rem 0 #00000080;
  --icon-warn: #b46300;
  --icon-success: #3d6b00;
  --icon-info: #09377c;
}

._80CRQq_icon {
  transition: all .3s;
  transform: translate(0)rotate(0);
}

._80CRQq_xs {
  font-size: .9rem;
}

._80CRQq_sm {
  font-size: 1.3rem;
}

._80CRQq_md {
  font-size: 1.6rem;
}

._80CRQq_lg {
  font-size: 2rem;
}

._80CRQq_xl {
  font-size: 2.4rem;
}

._80CRQq_xxxl {
  font-size: 6.8rem;
}

._80CRQq_none {
  padding: 0;
}

._80CRQq_right {
  padding-right: .8rem;
}

._80CRQq_left {
  padding-left: .8rem;
}

._80CRQq_center {
  padding: 0 .8rem;
}

._80CRQq_rotate {
  transform-origin: center;
  display: inline-block;
  transform: rotate(180deg);
}

.TQ6Uua_alku-brand-primary {
  color: var(--alku-brand-primary);
}

.TQ6Uua_alku-icon-default {
  color: var(--alku-icon-default);
}

.TQ6Uua_alku-black {
  color: var(--alku-black);
}

.TQ6Uua_alku-white {
  color: var(--alku-white);
}

.TQ6Uua_icon-warn {
  color: var(--icon-warn);
}

.TQ6Uua_icon-success {
  color: var(--icon-success);
}

.TQ6Uua_icon-info {
  color: var(--icon-info);
}

.TQ6Uua_regular {
  font-weight: 400;
}

.TQ6Uua_semibold {
  font-weight: 600;
}

.TQ6Uua_bold {
  font-weight: 700;
}

.TQ6Uua_hyperlink {
  border-bottom: .1rem solid var(--bright-blue);
  color: var(--bright-blue);
}

.TQ6Uua_flex {
  display: flex;
}

.TQ6Uua_flex-wrap {
  flex-wrap: wrap;
}

.TQ6Uua_space-between {
  justify-content: space-between;
}

.TQ6Uua_align-center {
  align-items: center;
}

.TQ6Uua_flex-start {
  align-items: flex-start;
}

.TQ6Uua_relative {
  position: relative;
}

.TQ6Uua_u-sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.TQ6Uua_header {
  background: #fafafa;
  margin-bottom: 3.2rem;
  padding: 0 3.6rem;
  box-shadow: 0 .2rem .5rem #0000001f;
}

.TQ6Uua_header-logo {
  margin: 2rem 17.4rem 2rem 2rem;
}

.TQ6Uua_header-profile {
  background: none;
  border: none;
  align-items: center;
  display: flex;
}

.TQ6Uua_header-nav-wrapper {
  flex-grow: 1;
}

.TQ6Uua_header-avatar {
  align-items: center;
  display: flex;
}

.TQ6Uua_header-account-wrapper {
  z-index: 150;
  position: relative;
}

.TQ6Uua_flyout-overlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tt8iCq_one {
  letter-spacing: -.02rem;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
}

.tt8iCq_two {
  letter-spacing: -.01rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.tt8iCq_three {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
}

.tt8iCq_subheading {
  letter-spacing: -.016rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
}

.tt8iCq_subheading-small {
  letter-spacing: -.03rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.tt8iCq_detail {
  letter-spacing: -.01rem;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.73;
}

.tt8iCq_detail b {
  font-weight: 600;
}

.tt8iCq_nav-text {
  letter-spacing: .04rem;
  font-size: 1.8rem;
  line-height: 1;
}

.tt8iCq_title, .tt8iCq_link {
  letter-spacing: -.01rem;
  font-size: 1.8rem;
  line-height: 1;
  display: block;
}

.tt8iCq_link {
  color: inherit;
  text-decoration: underline;
}

.tt8iCq_metadata {
  letter-spacing: -.0145rem;
  font-size: 1.45rem;
  line-height: 1.1;
}

.tt8iCq_normal {
  letter-spacing: -.026rem;
  font-size: 1.3rem;
  line-height: 1.84;
}

.tt8iCq_small {
  letter-spacing: -.012rem;
  font-size: 1.2rem;
  line-height: 1.3;
}

.tt8iCq_menu-item {
  letter-spacing: -.0014rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.5;
}

._7t1vbW_flyout {
  z-index: 100;
  white-space: normal;
  text-align: left;
  border-radius: .5rem;
  max-width: 21.8rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
  position: relative;
  box-shadow: 0 .2rem .8rem #0000004d;
}

._7t1vbW_flyout._7t1vbW_open {
  display: block;
}

._7t1vbW_flyout._7t1vbW_closed {
  display: none;
}

._7t1vbW_flyout._7t1vbW_default {
  background: var(--alku-white);
  border: .1rem solid #ccc;
  border-radius: .5rem;
}

:is(._7t1vbW_flyout._7t1vbW_default, ._7t1vbW_flyout._7t1vbW_account)._7t1vbW_md {
  width: 15.6rem;
}

:is(._7t1vbW_flyout._7t1vbW_default, ._7t1vbW_flyout._7t1vbW_account)._7t1vbW_lg {
  width: 22.4rem;
}

._7t1vbW_flyout._7t1vbW_right {
  right: 0;
}

._7t1vbW_flyout._7t1vbW_left {
  left: 0;
}

._7t1vbW_flyout._7t1vbW_account {
  background-color: #f9f9f9;
}

._7t1vbW_flyout._7t1vbW_account:after {
  content: "";
  background: #f9f9f9;
  border-bottom-right-radius: .5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: -.8rem;
  right: 2rem;
  transform: rotate(45deg);
  box-shadow: -.2rem -.2rem .5rem -.3rem #0000004d;
}

._7t1vbW_flyout-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

._7t1vbW_flyout-list-item-btn {
  width: 100%;
}

._7t1vbW_default-wrapper {
  position: absolute;
  top: 100%;
  left: -2.4rem;
}

._7t1vbW_account-wrapper {
  position: absolute;
  top: 100%;
  right: -1.3rem;
}

._7t1vbW_position-right {
  right: 0;
  left: unset;
}

.bh3Kaq_nav {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.bh3Kaq_link {
  border-bottom: .4rem solid #0000;
  margin: 0 2.1rem;
  padding: 3rem 0;
  display: block;
}

.bh3Kaq_link:hover, .bh3Kaq_link.bh3Kaq_active {
  border-color: var(--alku-brand-primary);
  transition: all .2s;
}

._8mrmkq_modal {
  box-shadow: var(--shadow-modal);
  border: none;
  border-radius: .1rem;
  width: 100%;
  max-width: 80rem;
  max-height: 80vh;
  padding: 0;
  overflow-y: auto;
}

._8mrmkq_modal::backdrop {
  background-color: #0003;
}

._8mrmkq_modal ._8mrmkq_modal-close {
  position: absolute;
  top: 1.9rem;
  right: 2.1rem;
}

._8mrmkq_modal ._8mrmkq_modal-container {
  padding: 4.4rem 4.3rem;
}

._8mrmkq_modal ._8mrmkq_modal-title {
  align-items: center;
  margin-bottom: 3.6rem;
  display: flex;
}

.Y0Z-SW_container {
  width: 100%;
  max-width: 172.8rem;
  margin: auto;
  padding: 0 3.6rem;
}

.d5motq_dark-blue {
  color: var(--dark-blue);
}

.d5motq_black {
  color: var(--black);
}

.d5motq_brand-red {
  color: var(--brand-red);
}

.d5motq_brand-white-inactive {
  color: var(--white-inactive);
}

.d5motq_brand-white {
  color: var(--white);
}

.d5motq_bright-blue {
  color: var(--bright-blue);
}

.d5motq_dark-grey {
  color: var(--dark-grey);
}

.d5motq_grey-8 {
  color: var(--grey-8);
}

.d5motq_text-red {
  color: var(--text-red);
}

.d5motq_text-green {
  color: var(--text-green);
}

.d5motq_icon-favorite {
  color: var(--icon-favorite);
}

.d5motq_icon-default {
  color: var(--icon-default);
}

.d5motq_icon-warn {
  color: var(--icon-warn);
}

.d5motq_icon-success {
  color: var(--icon-success);
}

.d5motq_disabled-check {
  color: var(--disabled-check);
}

.d5motq_regular {
  font-weight: 400;
}

.d5motq_semibold {
  font-weight: 600;
}

.d5motq_bold {
  font-weight: 700;
}

.d5motq_flex {
  display: flex;
}

.d5motq_space-between {
  justify-content: space-between;
}

.d5motq_align-center {
  align-items: center;
}

.d5motq_align-top, .d5motq_flex-start {
  align-items: flex-start;
}

.d5motq_u-sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.d5motq_hidden {
  display: none;
}

.d5motq_label {
  width: auto;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
}

.d5motq_label.d5motq_required {
  position: relative;
}

.d5motq_label.d5motq_required:after {
  content: "*";
  color: var(--brand-red);
  position: absolute;
  top: -.1rem;
  right: -1rem;
}

.d5motq_input, .d5motq_select, .d5motq_textarea {
  border-width: 0 0 .2rem;
  border-color: var(--grey-5);
  width: 100%;
  padding: 1rem 1.3rem;
  font-size: 1.6rem;
}

.d5motq_error {
  background-color: var(--brand-red-light);
  border-width: 0 0 .2rem;
  border-color: var(--brand-red);
  opacity: 1;
}

.d5motq_input:read-only, .d5motq_select:disabled, .d5motq_textarea:read-only {
  background-color: var(--grey-1);
  color: var(--grey-6);
  opacity: 1;
}

.d5motq_textarea {
  resize: none;
  min-height: 8rem;
  line-height: 1.3;
  overflow-y: auto;
}

.d5motq_hyperlink {
  border-bottom: .1rem solid var(--bright-blue);
  color: var(--bright-blue);
}

.d5motq_container {
  border: var(--border-call-list);
  border-radius: .1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 2rem;
}

.d5motq_flyout-overlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d5motq_flyout-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

.d5motq_ellipsis-anim span {
  opacity: 0;
  animation: 1s infinite d5motq_ellipsis-dot;
}

.d5motq_ellipsis-anim span:first-child {
  animation-delay: 0s;
}

.d5motq_ellipsis-anim span:nth-child(2) {
  animation-delay: .1s;
}

.d5motq_ellipsis-anim span:nth-child(3) {
  animation-delay: .2s;
}

@keyframes d5motq_ellipsis-dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

:root {
  --brand-red: #d0021b;
  --brand-red-light: #f9efef;
  --white: #fff;
  --black: #000;
  --grey-1: #fafafa;
  --grey-2: #f9f9f9;
  --grey-3: #e9ebee;
  --grey-4: #f4f4f4;
  --grey-5: #666;
  --grey-6: #686f84;
  --grey-7: #ccc;
  --grey-8: #444;
  --grey-9: #ddd;
  --grey-10: #f2f2f2;
  --timezone-tag: #dfe3e9;
  --table-header: #404555;
  --dark-blue: #313648;
  --text-red: #b70016;
  --text-green: #407100;
  --bright-blue: #09377c;
  --dark-grey: #333;
  --border-call-list: .1rem solid #ddd;
  --border-avatar: .2rem solid var(--dark-grey);
  --border-form-inputs: .2rem solid var(--grey-5);
  --border-active-list: .6rem solid var(--brand-red);
  --border-leads-list: .6rem solid var(--grey-6);
  --border-add-button: .1rem solid #808288;
  --border-add-contact: .1rem solid #707483;
  --border-select-btm: .2rem solid #5c7199;
  --border-table: .1rem solid var(--grey-9);
  --disabled-radio: #999;
  --disabled-check: #888;
  --input-radio-check: #ab0015;
  --icon-favorite: #c28b00;
  --icon-default: #383a42;
  --icon-success: #3d6b00;
  --shadow-nav: 0 .2rem .5rem 0 #0000001f;
  --shadow-card: 0 .1rem .2rem 0 #00000059;
  --shadow-tag: 0 .1rem 0 0 #a8aab1;
  --shadow-modal: 0 .1rem .4rem 0 #00000080;
  --shadow-flyout: 0 .2rem .8rem 0 #0000004d;
  --shadow-tooltip: 0 .1rem .5rem 0 #0003;
  --shadow-error: #66000c4d;
  --shadow-warn: #592f004d;
  --shadow-success: #1934004d;
  --shadow-info: #1934004d;
  --fill-error: #f8eaec;
  --fill-warn: #fcf4d7;
  --fill-success: #f0f7e5;
  --fill-info: #f5f7fb;
  --icon-warn: #b46300;
  --white-inactive: #868ca4;
  --contact-row-left-padding: 2rem;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

[hidden] {
  display: none;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--black);
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  position: relative;
}

a {
  text-decoration: none;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a, button {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.form {
  display: block !important;
}

.form:has(.error) {
  background-color: #f9efef;
}

.o781xq_header {
  background: var(--grey-1);
  box-shadow: var(--shadow-nav);
  margin-bottom: 3.2rem;
  padding: 0 3.6rem;
}

@media (width >= 62em) {
  .o781xq_header {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
  }
}

.o781xq_logo, .o781xq_logo-mobile {
  min-width: 5rem;
  max-width: 12.5rem;
}

.o781xq_logo-mobile {
  padding: 1rem 0;
}

.o781xq_logo {
  display: none;
}

@media (width >= 62em) {
  .o781xq_logo {
    align-items: center;
    margin-right: 15rem;
    display: flex;
  }

  .o781xq_logo-mobile {
    display: none;
  }
}

.o781xq_img {
  width: auto;
}

.o781xq_nav {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media (width >= 62em) {
  .o781xq_nav {
    flex-direction: row-reverse;
    flex: 1;
    align-items: center;
  }
}

.o781xq_menu {
  width: 100%;
  display: flex;
}

@media (width >= 62em) {
  .o781xq_menu {
    width: unset;
    display: flex;
  }
}

.o781xq_link {
  border-bottom: .4rem solid #0000;
  margin: 0 2.1rem;
  padding: 3rem 0;
  display: block;
}

.o781xq_link:hover, .o781xq_link.o781xq_active {
  border-color: var(--brand-red);
  transition: all .2s;
}

.o781xq_profile {
  background: none;
  border: none;
  align-items: center;
  display: flex;
}

.o781xq_avatar {
  align-items: center;
  display: flex;
}

.o781xq_account-wrapper {
  z-index: 150;
  width: 21.3rem;
  position: absolute;
  top: 7.3rem;
  right: 2rem;
}

.o781xq_header-account-wrapper {
  z-index: 150;
  align-items: center;
  display: flex;
  position: relative;
}

.k_JnYa_container {
  width: 100%;
  max-width: 172.8rem;
  margin: auto;
  padding: 0 3.6rem;
  position: absolute;
  top: 45vh;
}

.B9_LqW_container {
  width: 100%;
  max-width: 172.8rem;
  margin: auto;
  padding: 0 3.6rem;
  position: absolute;
  top: 30vh;
}

.B9_LqW_img-container {
  max-width: 30.6rem;
  margin: 0 auto;
}

.B9_LqW_info {
  margin-top: 5rem;
}

.B9_LqW_logo {
  max-width: 30.6rem;
}

.B9_LqW_error-container {
  max-width: 64.6rem;
}

._94fhDW_container {
  color: var(--black);
  border-radius: .1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._94fhDW_container._94fhDW_error {
  background: var(--fill-error);
  box-shadow: 0 .1rem .1rem #66000c4d;
}

._94fhDW_container._94fhDW_success {
  background: var(--fill-success);
  box-shadow: 0 .1rem .1rem #1934004d;
}

._94fhDW_container._94fhDW_info {
  background: var(--fill-info);
  box-shadow: 0 .1rem .1rem #1934004d;
}

._94fhDW_container._94fhDW_alert {
  background: var(--fill-warn);
  box-shadow: 0 .1rem .1rem #592f004d;
}

._94fhDW_container._94fhDW_sm {
  padding: 1.2rem;
  font-size: 1.3rem;
  font-weight: 400;
}

._94fhDW_container._94fhDW_md {
  padding: 1.8rem 2.4rem;
  font-weight: 600;
}

._94fhDW_content {
  text-wrap: wrap;
  text-align: left;
  align-items: center;
  display: flex;
}

._94fhDW_text {
  padding-left: 1rem;
  line-height: 1.5;
}

.WXqopW_row {
  flex-flow: wrap;
  width: 100%;
  display: flex;
}

.WXqopW_column {
  flex-direction: column;
  flex: 1;
  margin: 1rem;
  display: flex;
}

.WXqopW_max-width {
  max-width: 400px;
}

.WXqopW_loader {
  border: 3px solid var(--alku-grey-1);
  border-top: 3px solid var(--alku-brand-primary);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  animation: 2s linear infinite WXqopW_spin;
}

.WXqopW_sm-loader {
  border: 2px solid var(--alku-white);
  border-top: 2px solid var(--alku-brand-primary);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 2s linear infinite WXqopW_spin;
}

@keyframes WXqopW_spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.WXqopW_mt-1 {
  margin-top: 1rem;
}

.WXqopW_mt-2 {
  margin-top: 2rem;
}

.WXqopW_width-100 {
  width: 100%;
}

.WXqopW_pt-1 {
  padding-top: .5rem;
}

._26O0zq_bg {
  border: var(--alku-border-default);
  background-color: var(--alku-grey-1);
  border-radius: .1rem;
  margin: 2rem 0;
  padding: 2rem 2rem 1rem;
}

._26O0zq_wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

._26O0zq_loader-wrapper {
  align-items: center;
  width: 21.5px;
  height: 21.5px;
  margin-right: .8rem;
  display: flex;
}

._26O0zq_min-width-32 {
  min-width: 32px;
}

._26O0zq_pl-3 {
  padding-left: 3rem;
}

._26O0zq_pl-6 {
  padding-left: 6rem;
}

._26O0zq_cursor-pointer {
  cursor: pointer;
}

._26O0zq_center {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

._26O0zq_mt-2 {
  margin-top: 2rem;
}

._26O0zq_font-sm {
  font-size: small;
}

._26O0zq_pb-1 {
  padding-bottom: 1rem;
}

._26O0zq_disabled {
  color: var(--alku-icon-disabled);
  cursor: auto;
  pointer-events: none;
}

._26O0zq_font-size-16 {
  font-size: 16px;
}

.B8ETRa_file-upload {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.B8ETRa_drop-zone {
  border: 2px dashed var(--alku-brand-primary);
  text-align: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
}

.B8ETRa_upload-icon {
  color: var(--alku-brand-primary);
  margin-bottom: 2rem;
  font-size: 5rem !important;
}

.B8ETRa_drop-zone button {
  margin: 1rem 0;
}

.B8ETRa_file-size-info {
  color: #555;
  margin-top: 10px;
  font-size: 12px;
}

.B8ETRa_file-list {
  width: 100%;
  margin-top: 2rem;
}

.B8ETRa_file-item {
  background-color: var(--alku-grey-1);
  border: 1px solid #ccc;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px;
  display: flex;
}

.B8ETRa_file-error {
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.B8ETRa_action-buttons {
  justify-content: flex-end;
  margin-top: 3rem;
  display: flex;
}

.B8ETRa_mr-2 {
  margin-right: 2rem;
}
/*# sourceMappingURL=index.eec369d2.css.map */
