@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

/* Variables */
:root {
  /* colors */
  --alku-brand-primary: #d0021b;
  --alku-brand-primary-light: #f9efef;

  --alku-icon-default: #383a42;
  --alku-icon-disabled: #888888;

  --alku-grey-1: #f9f9f9;
  --alku-white: #ffffff;
  --alku-black: #000000;

  /* misc */
  --alku-border-default: 0.1rem solid #707483;
  --shadow-modal: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.5);

  /* icons */
  --icon-warn: #b46300;
  --icon-success: #3d6b00;
  --icon-info: #09377c;
}

/* Potentially against a11y best practice, but a widely adopted deviation from browser defaults */
a,
button {
  cursor: pointer;
}

/* Overflow Utility */
.overflow-hidden {
  overflow: hidden;
}

/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
menu,
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Base Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

html {
  font-size: 62.5%; /* equates to 10px on most browsers which makes easy math for rems */
}

body {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--black);
  position: relative;
}

a {
  text-decoration: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
